import './App.css';

function App() {
    return (
        <div>

            <h1><i className="fas fa-flask"></i> 91 developments</h1>

            <header>
                <h2>We connect <br/>healthcare + technology</h2>

                <p>
                    We develop and grow telehealth brands that deliver safe, effective and accessible digital health care in Australia.
                </p>

                    <p><i className="far fa-envelope"></i> Get in touch - <a
                    href="mailto:hello@91developments.com">hello@91devlopments.com</a></p>



            </header>

            <footer style={{fontSize: 12}}>
91 Developments Pty Ltd - 3 Prospect Street, Fortitude Valley QLD 4006
            </footer>
        </div>
    );
}

export default App;
